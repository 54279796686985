import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import theme from './theme';
import './types';
import { ChatProvider } from './components/ChatProvider';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { BrowserRouter } from 'react-router-dom';
import LanguageProvider from './localization/LanguageProvider';
import './styles/global.css';
const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      {/* <ErrorDialog dismissError={() => setError(null)} error={error} /> */}
      <ChatProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ChatProvider>
    </VideoProvider>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <LanguageProvider>
      <UnsupportedBrowserWarning>
        <AppStateProvider>
          <VideoApp />
        </AppStateProvider>
      </UnsupportedBrowserWarning>
    </LanguageProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
);
