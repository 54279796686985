import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  makeStyles,
  Snackbar,
  SnackbarContent,
  Switch,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
// import BookingDetails from '../../IntroContainer/UserMenu/bookingDetails/BookingDetails';
import styles from './RoomNameScreen.module.css';

import { CloseOutlined } from '@material-ui/icons';
import { useSearchParams } from 'react-router-dom';
import useClientThemeConfig from '../../../hooks/useClientThemeConfig/useClientThemeConfig';
import { useAppState } from '../../../state';
import CustomFormattedMessage from '../../CustomFormattedMessage/CustomFormattedMessage';
import { getClientAsset, getLogo } from '../../../utils/getClientThemeConfig';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    fontSize: '34px',
    fontFamily: 'Nunito',
    fontWeight: 700,
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
      marginBottom: '1.5rem',
    },
  },

  inputContainer: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 400,

    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 1em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '.5rem',
    },
  },

  consentTerm: {
    display: 'flex',
    flexDirection: 'row',

    alignItems: 'start',

    paddingTop: '5px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      marginTop: '.5rem',
    },
  },

  consentText: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 400,
    marginTop: '0px',
  },

  checkbox: {
    height: '35px',
    width: '35px',
    marginTop: '-5px',
    marginRight: '15px',
    [theme.breakpoints.down('sm')]: {
      height: '25px',
      width: '25px',
      marginTop: '-5px',
      marginRight: '15px',
    },
  },

  privacyPolicy: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 600,
    color: 'black',
    marginLeft: '5px',
  },

  textFieldContainer: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 400,

    width: '100%',
  },

  hostButton: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '70px',
    marginBottom: '-45px',
    background: 'none',
    border: 'none',
    fontSize: '10px',
    fontFamily: 'Nunito',
    fontWeight: 400,

    [theme.breakpoints.down('sm')]: {
      padding: '5px',
      fontSize: '16px',
      width: '100%',
      marginTop: '25px',
      marginBottom: '-20px',
    },
  },
  snackbar: {
    backgroundColor: theme.palette.error.main,
  },
  close: {
    padding: theme.spacing(0.5),
    color: '#ffffff',
  },
  continueButton: {
    fontSize: '20px',
    fontFamily: 'Nunito',
    fontWeight: 400,
    padding: '10px',

    background: 'var(--primary-main)',
    color: '#fff',

    border: 'none',
    borderRadius: '43.5px',

    boxShadow: ' 0px 8px 13px 3px rgba(0, 0, 0, 0.25)',

    width: '160px',

    [theme.breakpoints.down('sm')]: {
      padding: '5px',
      width: '30%',
      marginTop: '-20px',
      fontSize: '18px',
    },
    float: 'right',
  },

  hostKeyText: {
    position: 'relative',
    bottom: '-7px',
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 400,

    [theme.breakpoints.down('sm')]: {
      width: '50%',
      fontSize: '16px',
      marginTop: '5px',
    },
  },
  bookingDetails: {
    backgroundColor: '#78aq45',
    color: 'white',
    lineHeight: '15px',
    fontSize: '18px',
    fontFamily: 'Nunito',
    fontWeight: 400,
    position: 'absolute',
    left: '30px',
    bottom: '20px',
    width: '300px',
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setConsent: (name: boolean) => void;
  setName: (name: string) => void;
  setHostKey: (hostKey: string) => void;
  onSubmit: () => void;
  isValid: boolean | null;
}

export default function RoomNameScreen({
  name,
  setName,
  roomName,
  setConsent,
  onSubmit,
  setHostKey,
  isValid,
}: RoomNameScreenProps) {
  const theme = useTheme();
  const classes = useStyles();
  const { roomAndHostKeyValid, setRoomAndHostKeyValid, validateRoomAndHostKey } = useAppState();
  const [useHost, setUseHost] = useState<boolean>(false);
  const [inputHostKey, setInputHostKey] = useState<string>('');
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') || 'en';
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [recordingIsAble, setRecordingIsAble] = useState(false);

  const { config } = useClientThemeConfig();

  const logo = getLogo(lang);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleHostKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputHostKey(event.target.value);
    setHostKey(event.target.value);
  };

  const toggleHost = () => {
    if (useHost) {
      setHostKey('');
    } else {
      setHostKey(inputHostKey);
    }

    setUseHost(!useHost);
  };

  const handleConsent = () => {
    setRecordingIsAble(!recordingIsAble);
    setConsent(!recordingIsAble);
  };

  const handleSubmit = () => {
    validateRoomAndHostKey(name, roomName, inputHostKey).then(() => {
      if (roomAndHostKeyValid) {
        setSnackbarOpen(false);
      } else {
        setSnackbarOpen(true);
      }
    });

    onSubmit?.();
  };

  return (
    <>
      <div className={styles.title}>
        <img width={config?.branding.logo_width} src={logo} alt="Logo" />
      </div>
      <div className={styles.InputContainer}>
        <div>
          <TextField
            id="input-user-name"
            variant="outlined"
            fullWidth
            size="small"
            value={name}
            onChange={handleNameChange}
            placeholder={lang === 'fr' ? 'Votre nom' : 'Your name'}
          />
        </div>
        {useHost ? (
          <>
            <div>
              <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={inputHostKey}
                onChange={handleHostKeyChange}
                placeholder="Host key"
              />
            </div>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClick={() => setSnackbarOpen(false)}>
              <SnackbarContent
                className={classes.snackbar}
                message={<CustomFormattedMessage id="invalid_host_key_1" />}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    className={classes.close}
                    onClick={() => setSnackbarOpen(false)}
                  >
                    <CloseOutlined />
                  </IconButton>
                }
              />
            </Snackbar>
          </>
        ) : (
          <>
            <FormGroup style={{ display: 'flex', alignItems: 'flex-start' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleConsent}
                    disableRipple={true}
                    style={{ alignSelf: 'flex-start', padding: 0, paddingLeft: '9px', paddingRight: '5px' }}
                    size="small"
                  />
                }
                label={<CustomFormattedMessage id="consent" />}
                style={{ alignSelf: 'flex-start' }}
              />
            </FormGroup>
          </>
        )}
      </div>
      <div className={styles.ButtonsContainer}>
        <div className={styles.hostSwitch}>
          <Switch color="primary" checked={useHost ? true : false} onChange={toggleHost} />
          <span className={styles.hostText}>
            <CustomFormattedMessage id="join_as_host" />
          </span>
        </div>

        <Button
          disabled={useHost ? !inputHostKey || !name.trim() : !name.trim()}
          onClick={e => {
            handleSubmit();
          }}
          variant="contained"
        >
          <CustomFormattedMessage id="join" />
        </Button>
      </div>
    </>
  );
}
