import React, { createContext, useContext, useReducer, useState } from 'react';
import { AllowRecordingType, RecordingRules, RoomType } from '../types';
import { RemoteParticipant, TwilioError } from 'twilio-video';
import { ServiceRequest } from './requests';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import { User } from 'firebase';
import jwt_decode from 'jwt-decode';

export const disconnect_endpoint = process.env.DISCONNECT_API_URL || '';

export enum UserRole {
  Host = 'host',
  Participant = 'participant',
  Translator = 'translator'
}

export interface JwtToken {
  grants: {
    flexUser: {
      roles: UserRole[];
    };
  };
}

export interface AuthInfo {
  tokenString: string;
  tokenDecoded: JwtToken;
}

export enum CallState {
  NoCall,
  Calling,
  Connected,
}
export type CallResponse = {
  callSid: string;
};

type CallStatus = 'queued' | 'ringing' | 'in-progress' | 'completed' | 'busy' | 'failed' | 'no-answer' | 'canceled';

export type CallStatusResponse = {
  callStatus: CallStatus;
};

export const CallStatusToStateMap = {
  queued: CallState.Calling,
  ringing: CallState.Calling,
  'in-progress': CallState.Connected,
  completed: CallState.NoCall,
  busy: CallState.NoCall,
  failed: CallState.NoCall,
  'no-answer': CallState.NoCall,
  canceled: CallState.NoCall,
};

export type CallInfo = {
  callState: CallState;
  callSid: string;
};

export type TaskInfo = {
  status: 'waiting' | 'callback';
  account: 'Dynacare' | 'CitizenCare';
  patientPhone?: string;
  sfFlowUrl?: string;
};

export interface StateContextType {
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  getToken(name: string, room: string, hostKey?: string): Promise<{ room_type: RoomType; token: string; error?: any }>;
  authInfo?: AuthInfo;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>;
  // workaround to address issue where were not able to stop the recording
  allowRecording: AllowRecordingType;
  setAllowRecording: React.Dispatch<AllowRecordingType>;
  validateRoomAndHostKey(name: string, room: string, hostKey?: string): any;
  roomAndHostKeyValid: boolean;
  setRoomAndHostKeyValid: React.Dispatch<React.SetStateAction<boolean>>;
  patientCallInfo: CallInfo;
  translatorCallInfo: CallInfo;
  setPatientCallInfo: (callInfo: CallInfo) => void;
  setTranslatorCallInfo: (callInfo: CallInfo) => void;
  closeRoom: (roomSid: string) => void;
  makeServiceRequest(serviceRequest: ServiceRequest): Promise<Response>;

  // removeParticipant(roomSid: string, participant: RemoteParticipant): Promise<any>;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchParams = Object.fromEntries(urlSearchParams.entries());
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [roomType, setRoomType] = useState<RoomType>();
  const [authInfo, setAuthInfo] = useState<AuthInfo | undefined>(undefined);
  // workaround to address issue where were not able to stop the recording
  const [allowRecording, setAllowRecording] = useState('init');

  const [roomAndHostKeyValid, setRoomAndHostKeyValid] = useState(false);

  const [patientCallInfo, setPatientCallInfo] = useState<CallInfo>({
    callSid: '',
    callState: CallState.NoCall,
  });
  const [translatorCallInfo, setTranslatorCallInfo] = useState<CallInfo>({
    callSid: '',
    callState: CallState.NoCall,
  });

  let contextValue = {
    error,
    setError,
    isFetching,
    roomAndHostKeyValid,
    setRoomAndHostKeyValid,
    allowRecording,
    setAllowRecording,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    authInfo,
  } as StateContextType;

  async function makeServiceRequest(serviceRequest: ServiceRequest): Promise<Response> {
    let body = Object.assign(
      {
        taskSid: searchParams.tasksid ?? '',
        jwt: authInfo?.tokenString ?? '',
        room: searchParams.room,
      },
      serviceRequest.body ?? {}
    );

    const baseUrl = process.env.REACT_APP_SERVICE_URL || '';

    return await fetch(`${baseUrl}${serviceRequest.path}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      method: 'POST',
    });
  }

  const validateRoomAndHostKey = async (name: string, room: string, hostKey?: string) => {
    const baseUrl = `${process.env.REACT_APP_SERVICE_URL || ''}/validateRoomAndHostKey`;
    return fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        room,
        hostKey: hostKey || '',
      }),
    })
      .then(async res => {
        console.log(res);
        if (!res.ok) {
          const resp = await res.json();
          console.error(error);
          return Promise.reject(new Error(resp.error.message));
        } else {
          setRoomAndHostKeyValid(true);
        }
      })
      .catch(err => setError(err));
  };

  contextValue = {
    ...contextValue,
    makeServiceRequest,
    validateRoomAndHostKey,
    translatorCallInfo,
    setTranslatorCallInfo,
    patientCallInfo,
    setPatientCallInfo,
    closeRoom: async roomSid => {
      const endpoint = `${process.env.REACT_APP_SERVICE_URL || ''}/rooms/closeRoom`;
      return fetch(endpoint, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          roomSid,
        }),
      }).then(res => res.json());
    },
    getToken: async (username, room, hostKey) => {
      const endpoint = `${process.env.REACT_APP_SERVICE_URL || ''}/token`;
      return fetch(endpoint, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          username,
          room,
          hostKey,
        }),
      }).then(res => res.json());
    },
    updateRecordingRules: async (room_sid, rules) => {
      const endpoint = `${process.env.REACT_APP_SERVICE_URL || ''}/rooms/updateRecordingRules`;
      console.log('Recording Rules:: room_sid', room_sid);
      return fetch(endpoint, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ room: room_sid, rules, jwt: authInfo?.tokenString }),
        method: 'POST',
      })
        .then(async res => {
          if (!res.ok) {
            const recordingError = new Error('There was an error updating recording rules');
            return Promise.reject(recordingError);
          }

          return res;
        })
        .catch(err => setError(err));
    },
    // removeParticipant: async (roomSid: string, participant: RemoteParticipant) => {
    //   const endpoint = `${process.env.REACT_APP_SERVICE_URL || ''}/participants/remove`;

    //   await fetch(endpoint, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ participantSid: participant.sid, jwt: authInfo?.tokenString, room: roomSid }),
    //     method: 'POST',
    //   });
    // },
  };

  const getToken: StateContextType['getToken'] = (name, room, hostKey) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room, hostKey)
      .then(res => {
        if (res.error) throw new Error(res.error.message);
        setRoomType(res.room_type);
        setIsFetching(false);
        setAuthInfo({
          tokenDecoded: jwt_decode<JwtToken>(res.token),
          tokenString: res.token,
        });
        console.log(res);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateRecordingRules: StateContextType['updateRecordingRules'] = (room_sid, rules) => {
    setIsFetching(true);
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken, updateRecordingRules }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
