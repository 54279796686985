import React from 'react';
import { useIntl } from 'react-intl';

interface CustomFormattedMessageProps {
  id: string;
}

const CustomFormattedMessage: React.FC<CustomFormattedMessageProps> = ({ id }) => {
  const { formatMessage } = useIntl();
  const message = formatMessage({ id });

  return <>{message.trim() && message !== id ? message : ''}</>;
};

export default CustomFormattedMessage;
