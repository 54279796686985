import { useState, useEffect } from 'react';
import { getClientThemeConfig } from '../../utils/getClientThemeConfig';

interface UseClientThemeConfigResult {
  config: Record<string, any> | null;
  loading: boolean;
  error: Error | null;
}

const useClientThemeConfig = (): UseClientThemeConfigResult => {
  const [config, setConfig] = useState<Record<string, any> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    // Fetch the configuration when the component mounts
    const fetchConfig = async () => {
      try {
        const themeConfig = getClientThemeConfig();
        setConfig(themeConfig);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchConfig();
  }, []);

  return { config, loading, error };
};

export default useClientThemeConfig;
