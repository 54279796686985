import { Button, Grid, Hidden, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useSyncState } from '../../../services/SyncProvider';
import { useAppState } from '../../../state';
import { IParticipantApproval, ParticipantApproval } from '../../../types';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import Snackbar from '../../Snackbar/Snackbar';
import { Steps } from '../PreJoinScreens';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import EngLogo from '../../../assets/eng-logo.svg';
import FrLogo from '../../../assets/fr-logo.svg';

import { useSearchParams } from 'react-router-dom';

import styles from './DeviceSelectionScreen.module.css';
import useClientThemeConfig from '../../../hooks/useClientThemeConfig/useClientThemeConfig';
import CustomFormattedMessage from '../../CustomFormattedMessage/CustomFormattedMessage';
import { getClientAsset, getLogo } from '../../../utils/getClientThemeConfig';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 400,
    marginBottom: '15px',
  },
  username: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 700,
  },

  localPreviewContainer: {
    marginBottom: '10px',
    marginTop: '10px',
  },

  mobileButtonBar: {
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'space-around',

    color: '#000',
  },
  mobileButton: {
    fontSize: '16px',
    padding: '0.8em 0',
    margin: 0,
  },

  actionButtonsContainer: {
    marginTop: '1.3rem',
  },

  joinButton: {
    padding: '0.5rem 0.5rem',
    color: '#fff',
    background: 'var(--primary-main)',
    borderRadius: '20px',
    border: 'none',
    boxShadow: '0px 3px 5px rgb(0 0 0 / 25%)',
    fontSize: '18px',
    fontFamily: 'Nunito',

    width: '140px',
    marginLeft: '1rem',
    marginRight: '1rem',
    '&:hover': {
      background: 'var(--primary-main)',
    },
  },

  joinButtonLoading: {
    color: '#fff',
  },

  cancelButton: {
    padding: '0.4rem 0.4rem',
    color: 'var(--primary-main)',
    background: 'none',
    borderRadius: '20px',
    fontSize: '18px',
    fontFamily: 'Nunito',
    border: '2px solid var(--primary-main)',

    width: '140px',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  title: {
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '20px',
  },

  LogoContainer: {
    width: '15rem',
    marginBottom: '20px',
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  hostKey: string;
  consent: boolean;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({
  name,
  roomName,
  setStep,
  hostKey,
  consent,
}: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { config } = useClientThemeConfig();
  const { getToken, isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [approval, setApproval] = useSyncState<IParticipantApproval>(roomName || 'default');
  const [clickedJoin, setClickedJoin] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') || 'en';

  const isHost = hostKey.length !== 0;

  const number = approval?.participants.filter(obj => obj.approved === ParticipantApproval.PENDING).length || 0;

  const message = `There are ${number} user(s) in the waiting room. Once you login you will be prompted to accept them.`;

  const logo = getLogo(lang);

  const joinCall = () => {
    getToken(name, roomName, hostKey).then(({ token }) => {
      videoConnect(token);
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    });
  };

  const participantApproval = useMemo(
    () => approval?.participants.find(_participants => _participants.name === name)?.approved,
    [approval?.participants, name]
  );

  const handleJoin = () => {
    setClickedJoin(true);
    if (isHost) {
      if (!approval?.hosts.find(_hosts => _hosts.name === name)) {
        let oldApprovals = [...(approval?.participants || [])];
        let oldHosts = [...(approval?.hosts || [])];
        setApproval({ hosts: [...oldHosts, { name }], participants: [...oldApprovals] });
      }
      joinCall();
    } else {
      // Don't allow the same user to ask multiple times, but allow them to ask again if denied
      if (
        !approval?.participants.find(
          _participants => _participants.name === name && _participants.approved !== ParticipantApproval.DENIED
        )
      ) {
        let oldApprovals = [...(approval?.participants || [])];
        let oldHosts = [...(approval?.hosts || [])];
        oldApprovals = oldApprovals.filter(_approval => _approval.name !== name);
        setApproval({
          hosts: [...oldHosts],
          participants: [...oldApprovals, { name, approved: ParticipantApproval.PENDING, consent }],
        });
      }
    }
  };

  useEffect(() => {
    if (participantApproval === ParticipantApproval.APPROVED && clickedJoin) {
      joinCall();
    }
  }, [participantApproval, clickedJoin]);

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
            <CustomFormattedMessage id="loading"></CustomFormattedMessage>
          </Typography>
        </div>
      </Grid>
    );
  }

  if (participantApproval === ParticipantApproval.PENDING) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <div className={classes.title}>
            <img src={logo} width={config?.branding.logo_width} />
          </div>
          <Typography
            variant="body2"
            style={{
              marginBottom: '20px',
              marginTop: '25px',
              fontWeight: 'bold',
              fontSize: '16px',
              textAlign: 'center',
            }}
          >
            <p>
              <CustomFormattedMessage id="waiting_host"></CustomFormattedMessage>
            </p>
          </Typography>
          <Typography variant="body2" style={{ fontSize: '16px', textAlign: 'center' }}>
            <CustomFormattedMessage id="waiting_host_2"></CustomFormattedMessage>
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Snackbar
        variant="error"
        headline="Your request to join was rejected:"
        message="The call host has declined your request to join. "
        open={participantApproval === ParticipantApproval.DENIED}
      />
      <Snackbar variant="info" headline={message} message="" open={isHost && number > 0} />
      <Typography variant="h5" className={classes.gutterBottom}>
        <span className={classes.username}>{name}</span>,{' '}
        <CustomFormattedMessage id="adjust_mesage"></CustomFormattedMessage>
      </Typography>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <SettingsMenu mobileButtonClass={classes.mobileButton} />
            </Hidden>
          </div>

          <div className={classes.mobileButtonBar}>
            <Hidden smDown>
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <SettingsMenu mobileButtonClass={classes.mobileButton} />
            </Hidden>
          </div>

          <div className={classes.actionButtonsContainer}>
            <Grid container justify="center" alignItems="center">
              <Button
                variant="text"
                style={{ marginRight: '20px', paddingLeft: '15px', paddingRight: '15px' }}
                onClick={() => setStep(Steps.roomNameStep)}
              >
                <CustomFormattedMessage id="cancel"></CustomFormattedMessage>
              </Button>
              {(isHost || participantApproval !== ParticipantApproval.DENIED) && (
                <Button variant="contained" onClick={handleJoin} disabled={disableButtons}>
                  <CustomFormattedMessage
                    id={isHost || participantApproval === ParticipantApproval.APPROVED ? 'join_now' : 'ask_join'}
                  />
                </Button>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
