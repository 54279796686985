import { isPlainObject } from 'is-plain-object';
import { UserRole } from '../state';
import { Participant } from 'twilio-video';
import { ParticipantIdentityDecoded } from '../types';

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj;

  const target: { [name: string]: any } = {};

  for (const key in obj) {
    const val = obj[key];
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  }

  return target as T;
}

export async function getDeviceInfo() {
  const devices = await navigator.mediaDevices.enumerateDevices();

  return {
    audioInputDevices: devices.filter(device => device.kind === 'audioinput'),
    videoInputDevices: devices.filter(device => device.kind === 'videoinput'),
    audioOutputDevices: devices.filter(device => device.kind === 'audiooutput'),
    hasAudioInputDevices: devices.some(device => device.kind === 'audioinput'),
    hasVideoInputDevices: devices.some(device => device.kind === 'videoinput'),
  };
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name: PermissionName) {
  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name });
      return result.state === 'denied';
    } catch {
      return false;
    }
  } else {
    return false;
  }
}


const identityRegex = /^\[(\w+)\(([\w\d]*)\)\](.*)/i;
export function decodeParticipantIdentity(participantName: string): ParticipantIdentityDecoded {
  const match = identityRegex.exec(participantName);

  if (!match) {
    return {
      name: 'Participant',
      role: UserRole.Participant,
    };
  }

  if (match[1].toLowerCase() === 'translator') {
    return {
      name: 'Translator',
      role: UserRole.Translator,
    };
  }

  return {
    name: match[3] || 'Participant',
    role: match[1].toLowerCase() as UserRole,
  };
}
