import React, { createContext, useContext, useState, useEffect } from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { getClientThemeConfig } from '../utils/getClientThemeConfig';

export enum Language {
  English = 'en',
  French = 'fr',
}

export type LanguageContextType = {
  currentLanguage: Language;
  setLanguage: (lang: Language) => void;
};

export const LanguageContext = createContext<LanguageContextType>({
  currentLanguage: Language.English,
  setLanguage: () => {}, // Placeholder for the setter
});

export default function LanguageProvider(props: React.PropsWithChildren<{}>) {
  const [language, setLanguage] = useState<Language>(Language.English);
  const [translations, setTranslations] = useState<Record<string, any>>({});

  useEffect(() => {
    // Get the configuration when the component mounts
    const config = getClientThemeConfig();
    const defaultTranslations = config.translations || {};

    // Set the translations for the current language
    setTranslations(defaultTranslations[language] || {});

    // Update language from URL params if applicable
    const urlSearchParams = new URLSearchParams(window.location.search);
    const langParam: string = urlSearchParams.get('lang') ?? '';

    if (Object.values(Language).includes(langParam as Language)) {
      setLanguage(langParam as Language);
    }
  }, [language]);

  return (
    <LanguageContext.Provider value={{ currentLanguage: language, setLanguage }}>
      <IntlProvider messages={translations} locale={language} defaultLocale={Language.English}>
        {props.children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}

export function useLanguageState() {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguageState must be used within the LanguageProvider');
  }
  return context;
}
