import { createTheme } from '@material-ui/core/styles';
import { getClientThemeConfig } from './utils/getClientThemeConfig'; // Ensure this function is implemented correctly

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    sidebarWidth: number;
    sidebarMobileHeight: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
    rightDrawerWidth: number;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
    sidebarMobileHeight?: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
    rightDrawerWidth?: number;
  }
}

// Function to create the theme based on client or default settings
const createCustomTheme = () => {
  const themeConfig = getClientThemeConfig();

  return createTheme({
    overrides: {
      MuiButton: {
        root: {
          fontSize: '1rem',
          padding: '7px 30px',
          borderRadius: '4px',
          textTransform: 'none',
          color: themeConfig.branding.text_color || 'rgb(40, 42, 43)',
          transition: createTheme().transitions.create(['background-color', 'box-shadow', 'border', 'color'], {
            duration: createTheme().transitions.duration.short,
          }),
        },
        contained: {
          boxShadow: 'none',
          backgroundColor: themeConfig.branding.button_bg_color,
          color: themeConfig.branding.button_text_color,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: themeConfig.branding.button_bg_hover_color,
            color: themeConfig.branding.button_text_hover_color,
          },
        },
        outlinedPrimary: {
          border: `2px solid ${themeConfig.branding.button_primary_border || '#027AC5'}`,
          '&:hover': {
            border: `2px solid ${themeConfig.branding.button_primary_border_hover || 'rgb(1, 85, 137)'}`,
          },
        },
        startIcon: {
          marginRight: '6px',
        },
      },
      MuiTypography: {
        body1: {
          color: themeConfig.branding.text_color || 'rgb(40, 42, 43)',
          fontSize: '0.84rem',
        },
      },
      MuiSwitch: {
        root: {
          '& .MuiIconButton-root': {
            backgroundColor: 'transparent !important',
          },
        },
      },
      MuiCheckbox: {
        root: {
          color: themeConfig.branding.primary_color,
          '&$checked': {
            color: 'green',
          },
          '&:hover, &.Mui-checked:hover': {
            backgroundColor: 'transparent !important',
          },
          // Override the ripple effect
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
        },
      },
      MuiInputBase: {
        root: {
          fontSize: '0.9rem',
        },
      },
      MuiSelect: {
        root: {
          padding: '0.85em',
        },
      },
      MuiDialogActions: {
        root: {
          padding: '16px',
        },
      },
      MuiTextField: {
        root: {
          color: themeConfig.branding.text_color || 'rgb(40, 42, 43)',
        },
      },
      MuiInputLabel: {
        root: {
          color: themeConfig.branding.text_color || 'rgb(40, 42, 43)',
          fontSize: '1.1rem',
          marginBottom: '0.2em',
          fontWeight: 500,
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: themeConfig.branding.input_border_color || 'rgb(136, 140, 142)',
        },
      },
    },
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
    palette: {
      primary: {
        main: themeConfig.branding.primary_color || '#027AC5',
      },
      secondary: {
        main: themeConfig.branding.primary_color, // Replace with your secondary color
      },
      error: {
        main: '#d14036',
      },
    },
    brand: themeConfig.branding.primary_color || '#E22525',
    footerHeight: 72,
    mobileFooterHeight: 56,
    sidebarWidth: 300,
    sidebarMobileHeight: 90,
    sidebarMobilePadding: 8,
    participantBorderWidth: 2,
    mobileTopBarHeight: 52,
    rightDrawerWidth: 320,
  });
};

const theme = createCustomTheme();

export default theme;
