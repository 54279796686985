import merge from 'lodash/merge';
import defaultTranslations from '../configs/translations.json';
import clientsConfig from '../configs/clients.json';

interface ClientConfig {
  public_url: string;
  title: string;
  assets_folder: string;
  branding: Record<string, any>;
  translations?: Record<string, any>;
}

interface Configs {
  clients: ClientConfig[];
}

const getClientThemeConfig = (): Record<string, any> => {
  try {
    // Find the client-specific configuration
    let clientSpecificConfig: ClientConfig =
      clientsConfig.clients?.find(client => client.public_url.toLowerCase() === process.env.REACT_APP_CLIENT_URL) ||
      ({} as ClientConfig);

    if (!clientSpecificConfig) {
      console.log(`Client not found for URL: ${process.env.REACT_APP_CLIENT_URL}. Assuming default.`);

      clientSpecificConfig = clientsConfig.clients[0] || {};
    }

    // Merge the default settings with the client's settings
    clientSpecificConfig.translations = merge({}, defaultTranslations, clientSpecificConfig.translations || {});

    // Return the merged settings at the same level as other client properties
    return clientSpecificConfig;
  } catch (error) {
    // Log the error with additional context
    console.error('Error fetching theme configurations for client:', process.env.REACT_APP_CLIENT_URL, error);

    // Return an empty object or some default config
    return {};
  }
};

const getClientAsset = (asset: string) => {
  const clientConfig = getClientThemeConfig();
  return require(`../assets/clients/${clientConfig.assets_folder}/${asset}`).default;
};

const getLogo = (lang = 'en', mode = 'light') => {
  const clientConfig = getClientThemeConfig();
  let logo = '';

  if (mode === 'light') {
    logo = lang === 'fr' ? clientConfig?.branding.logo_fr : clientConfig?.branding.logo;
  } else {
    logo = lang === 'fr' ? clientConfig?.branding.logo_dark_fr : clientConfig?.branding.logo_dark;
  }

  // Fallback to the default logo if none of the conditions matched
  if (!logo) {
    logo = clientConfig?.branding.logo;
  }

  return getClientAsset(logo);
};

const getBackground = (lang = 'en', mode = 'light') => {
  const clientConfig = getClientThemeConfig();

  let background = '';

  if (mode === 'light') {
    background = lang === 'fr' ? clientConfig?.branding.background_image_fr : clientConfig?.branding.background_image;
  } else {
    background =
      lang === 'fr' ? clientConfig?.branding.background_image_dark_fr : clientConfig?.branding.background_image_dark;
  }

  // Fallback to the default logo if none of the conditions matched
  if (!background) {
    background = clientConfig?.branding.background_image || null;
  }

  return background ? getClientAsset(background) : null;
};

export { getClientThemeConfig, getClientAsset, getLogo, getBackground };
