import React from 'react';

import Button from '@material-ui/core/Button';
import MicIcon from '../../../assets/MicOn.svg';
import MicOffIcon from '../../../assets/MicOff.svg';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { FormattedMessage } from 'react-intl';

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  return (
    <Button
      className={props.className}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || props.disabled}
      data-cy-audio-toggle
    >
      {isAudioEnabled ? <img src={MicIcon} /> : <img src={MicOffIcon} />}
      {/* {!hasAudioTrack ? (
        <CustomFormattedMessage id="no_audio"></CustomFormattedMessage>
      ) : isAudioEnabled ? (
        <CustomFormattedMessage id="audioOn"></CustomFormattedMessage>
      ) : (
        <CustomFormattedMessage id="audioOff"></CustomFormattedMessage>
      )} */}
    </Button>
  );
}
